import React, {
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react'

import { auth, collections } from '../../services/firebase'

import { IUserAuthContext, IAuthContext } from './types'

const AuthContext = createContext<IAuthContext>({} as IAuthContext)

export const AuthProvider: React.FC = (props) => {
  const { children } = props

  const [user, setUser] = useState<IUserAuthContext | null>()

  useEffect(() => {
    // Necessário se não o snapshot continuará stremando mesmo após o sign-out do user.
    let unsubscribeSnapshot: any = () => {}

    const unsubscribe = auth.onAuthStateChanged(async (userCred) => {
      if (userCred) {
        const { uid } = userCred

        const token = await userCred.getIdTokenResult()

        unsubscribeSnapshot = collections.users.doc(uid).onSnapshot((snapshot) => {
          const userDoc = snapshot.data()

          if (!userDoc) {
            return
          }

          setUser({
            uid,
            name: userDoc.displayName,
            email: userDoc.email,
            havePermission: (token.claims.admin || token.claims.business),
            tag: userDoc.business?.tag ?? '',
          })
        })

        return
      }

      setUser(null)
      unsubscribeSnapshot()
    })

    return () => {
      unsubscribeSnapshot()
      unsubscribe()
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        signed: !!user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext)
  return context
}
