import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAZor4eqxKcvxo4UI7vy9OVBU0-It4juHY',
  authDomain: 'carbee-4edd8.firebaseapp.com',
  databaseURL: 'https://carbee-4edd8.firebaseio.com',
  projectId: 'carbee-4edd8',
  storageBucket: 'carbee-4edd8.appspot.com',
  messagingSenderId: '349671829367',
  appId: '1:349671829367:web:93029fcd931af4e3a07b72',
  measurementId: 'G-CFV5G6Q1G9',
}

const app = firebase.default.initializeApp(firebaseConfig)

if (process.env.NODE_ENV === 'development') {
  app.firestore().useEmulator(String(process.env.REACT_APP_IPV4), Number(process.env.REACT_APP_FIREBASE_FIRESTORE_PORT))

  /* app.firestore().settings({
    host: `${process.env.REACT_APP_IPV4}:${process.env.REACT_APP_FIREBASE_FIRESTORE_PORT}`,
    ssl: false,
  }) */

  app.functions().useEmulator(`http://${process.env.REACT_APP_IPV4}`, Number(process.env.REACT_APP_FIREBASE_FUNCTIONS_PORT))
}

const db = app.firestore()
const auth = app.auth()
const functions = app.functions()

const collections = {
  orders: db.collection('washingOrders'),
  users: db.collection('users'),
  companiesCodes: db.collection('companiesCodes'),
}

export {
  db,
  auth,
  functions,
  app,
  collections,
}
