import React, { useState } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import {
  Menu, Home, Assignment, ExitToApp,
} from '@material-ui/icons'

import {
  Container, AppBar, Toolbar, IconButton,
  Drawer, List, ListItem, ListItemIcon, ListItemText, Typography,
} from '@material-ui/core'

import {
  Link,
} from 'react-router-dom'

// Contexts
import { useAuth } from '../../contexts/Auth'
import { auth } from '../../services/firebase'

const useStyles = makeStyles((theme: Theme) => createStyles({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    [theme.breakpoints.up('xs')]: {
      minWidth: '220px',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '300px',
    },
  },
}))

const DrawerItems = [
  {
    name: 'Início',
    to: '/',
    icon: 'Home',
  },
]

const Header: React.FC = () => {
  // Hooks
  const classes = useStyles()

  const { user } = useAuth()

  // States
  const [drawer, setDrawer] = useState(false)

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => setDrawer(true)}>
              <Menu />
            </IconButton>
            <div style={{ flexGrow: 1 }} />
            <Typography style={{ textTransform: 'capitalize' }}>{user?.name}</Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawer}
        onClose={() => setDrawer(false)}
        classes={{
          paper: classes.drawer,
        }}
      >
        <List className={classes.drawer}>
          {DrawerItems.map((item) => (
            <ListItem
              component={Link}
              to={item.to}
              button
              key={item.name}
              onClick={() => setDrawer(false)}
            >
              <ListItemIcon>
                {item.icon === 'Home' && (
                  <Home />
                )}
                {item.icon === 'Assignment' && (
                  <Assignment />
                )}
                {item.icon === 'ExitToApp' && (
                  <ExitToApp />
                )}
              </ListItemIcon>
              <ListItemText>{item.name}</ListItemText>
            </ListItem>
          ))}
          <ListItem
            button
            onClick={async () => {
              setDrawer(false)
              await auth.signOut()
            }}
          >
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText>Sair</ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </>
  )
}

export default Header
