const ordersApi = {
  getStatusColor: (id: string) => {
    let color

    switch (id) {
      case 'waiting-for-partner-to-accept': {
        color = 'orange'
        break
      }
      case 'waiting-for-payment': {
        color = 'grey'
        break
      }
      case 'waiting-for-partner':
      case 'partner-on-the-way':
      case 'partner-arrived': {
        color = 'green'
        break
      }
      case 'completed': {
        color = 'grey'
        break
      }
      case 'canceled': {
        color = 'red'
        break
      }
      default: {
        color = 'red'
      }
    }

    return color
  },
  getStatusName: (id: string) => {
    let washStatusName

    switch (id) {
      case 'waiting-for-partner-to-accept': {
        washStatusName = 'Aguardando confirmação'
        break
      }
      case 'waiting-for-payment': {
        washStatusName = 'Aguardando pagamento'
        break
      }
      case 'waiting-for-partner': {
        washStatusName = 'Agendamento confirmado'
        break
      }
      case 'partner-on-the-way': {
        washStatusName = 'Bee a caminho'
        break
      }
      case 'partner-arrived': {
        washStatusName = 'Bee chegou ao local'
        break
      }
      case 'completed': {
        washStatusName = 'Finalizado'
        break
      }
      case 'canceled': {
        washStatusName = 'Cancelado'
        break
      }
      default: {
        washStatusName = 'N/A'
      }
    }

    return washStatusName
  },
}

export default ordersApi
