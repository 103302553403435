import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'

// Routes
import ApplicationNavigation from './routes'

// Contexts
import { AuthProvider } from './contexts/Auth'

const App: React.FC = () => (
  <>
    <CssBaseline />
    <AuthProvider>
      <ApplicationNavigation />
    </AuthProvider>
  </>
)

export default App
