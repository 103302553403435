import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import { useAuth } from '../contexts/Auth'

import { Home, SignIn } from '../pages'

import { Header, LoadingBackdrop } from '../components'

const Routes: React.FC = () => {
  const { user, signed } = useAuth()

  if (typeof user === 'undefined') {
    return <LoadingBackdrop open />
  }

  if (user && !user.havePermission) {
    return <div>Sem permissão suficiente</div>
  }

  return (
    <Router>
      {signed ? (
        <Switch>
          <Route path="*">
            <Header />
            <Home />
          </Route>
        </Switch>
      ) : (
        <Switch>
          <Route path="*">
            <SignIn />
          </Route>
        </Switch>
      )}

    </Router>
  )
}

export default Routes
