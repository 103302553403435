import React, { useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { makeStyles } from '@material-ui/core/styles'

import {
  Container, Typography, TextField, Button, Avatar,
} from '@material-ui/core'

import Alert from '@material-ui/lab/Alert'
import { auth } from '../../services/firebase'

// Components
import { LoadingBackdrop } from '../../components'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  errorMessage: {
    margin: theme.spacing(2, 0, 1),
  },
}))

const SignIn: React.FC = () => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [apiMessageError, setApiMessageError] = useState<null | string>(null)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('É necessário informar um email.'),
      password: Yup.string()
        .required('É necessário informar uma senha.'),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        console.log(values)
        await auth.signInWithEmailAndPassword(values.email, values.password)
      } catch (err) {
        console.log(err)

        setApiMessageError('Email não encontrado ou senha inválida.')
        setIsLoading(false)
      }
    },
  })

  return (
    <Container component="main" maxWidth="xs">
      <LoadingBackdrop open={isLoading} />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Carbee Empresas
        </Typography>
        <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
          {apiMessageError != null && (
            <Alert className={classes.errorMessage} onClose={() => setApiMessageError(null)} severity="error">{apiMessageError}</Alert>
          )}
          <TextField
            error={!!(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
            label="Email"
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          <TextField
            error={!!(formik.touched.password && formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
            variant="standard"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </Button>
        </form>
      </div>
    </Container>
  )
}

export default SignIn
