import React, { useEffect, useMemo, useState } from 'react'
import {
  Grid, Container, makeStyles,
} from '@material-ui/core'
import MaterialTable, { Column } from '@material-table/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Skeleton from '@material-ui/lab/Skeleton'

import { ExportCsv, ExportPdf } from '@material-table/exporters'
import { useAuth } from '../../contexts/Auth'
import { collections } from '../../services/firebase'

import ordersApi from '../../services/api/orders'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(8, 0, 8),
  },
}))

interface Row {
  id: string;
  status: {
    id: string;
    name: string;
    color: string;
  };
  schedulingDate: Date;
  createdDate: Date;
  cars: string[];
  client: string;
  voucher: string;
}

const Home: React.FC = () => {
  const [isListLoading, setIsListLoading] = useState(true)

  const classes = useStyles()
  const { user } = useAuth()
  const [orders, setOrders] = useState<Row[]>([])
  const [columns] = useState<Column<Row>[]>([
    {
      field: 'id',
      title: 'ID',
    },
    {
      title: 'Cliente',
      field: 'client',
    },
    {
      title: 'Cupom',
      field: 'voucher',
    },
    {
      title: 'Carros',
      field: 'cars',
      render: (row) => {
        const { cars } = row
        return (
          <ul>
            {cars.map((value) => (
              <ul>
                <li>
                  {value}
                </li>
              </ul>
            ))}
          </ul>
        )
      },
    },
    {
      title: 'Status',
      field: 'status.id',
      lookup: {
        'waiting-for-payment': 'Aguardando pagamento',
        'waiting-for-partner-to-accept': 'Aguardando confirmação',
        'waiting-for-partner': 'Agendamento confirmado',
        'partner-on-the-way': 'A caminho',
        'partner-arrived': 'No local',
        completed: 'Finalizado',
        canceled: 'Cancelado',
      },
      render: (data) => {
        const { status } = data
        return (
          <Grid container alignItems="center">
            <FiberManualRecordIcon style={{ color: status.color, marginRight: 5 }} />
            {status.name}
          </Grid>
        )
      },
    },
    {
      title: 'Data do agendamento',
      field: 'schedulingDate',
      type: 'datetime',
      filtering: false,
    },
    {
      title: 'Criado em',
      field: 'createdDate',
      editable: 'never',
      type: 'datetime',
      defaultSort: 'desc',
      filtering: false,
    },
  ])

  const tag = useMemo(() => user?.tag.toUpperCase(), [user])

  useEffect(() => {
    const unsubscribe = collections.orders.onSnapshot((snapshot) => {
      const filtered = snapshot.docs.filter((value) => {
        if (!tag) return true

        const orderDoc = value.data()

        const companyName: string = orderDoc.company?.name ?? ''
        const voucherId: string = orderDoc.voucher?.id ?? ''

        return (companyName.toUpperCase().search(tag) !== -1 || voucherId.toUpperCase().search(tag) !== -1)
      })

      const data = filtered.map((doc) => {
        const orderDoc = doc.data()

        return {
          id: orderDoc.createdAt.seconds,
          status: {
            id: orderDoc.status,
            color: ordersApi.getStatusColor(orderDoc.status),
            name: ordersApi.getStatusName(orderDoc.status),
          },
          createdDate: orderDoc.createdAt.toDate(),
          schedulingDate: orderDoc.date.toDate(),
          cars: orderDoc.cars?.map((value: any) => `${value.model} ${value.color} ${value.plate}`) ?? [],
          client: orderDoc.user.displayName,
          voucher: orderDoc.voucher?.id ?? '',
        } as Row
      })

      setOrders(data)
      setIsListLoading(false)
    })

    return () => unsubscribe()
  }, [tag])

  if (isListLoading) {
    return (
      <Container maxWidth="xl" className={classes.root}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" height={500} />
          </Grid>
        </Grid>
      </Container>
    )
  }

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <MaterialTable
            title="Pedidos"
            columns={columns}
            data={orders}
            options={{
              actionsColumnIndex: -1,
              sorting: true,
              filtering: true,
              pageSize: 10,
              pageSizeOptions: [10, 50, 100, 200, 500],
              exportMenu: [{
                label: 'Export PDF',
                exportFunc: (cols, datas) => ExportPdf(cols, datas, 'carbee-pedidos'),
              }, {
                label: 'Export CSV',
                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'carbee-pedidos'),
              }],
              columnsButton: true,
            }}
            localization={{
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
              },
              body: {
                emptyDataSourceMessage: 'Sem resultados para mostrar',
              },
              pagination: {
                labelRowsSelect: 'linhas',
                labelDisplayedRows: '{from}-{to} de {count}',
              },
            }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Home
